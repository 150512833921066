import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Playlist from "../components/Playlist"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="Home" />
        <Playlist />
      </Layout>
    )
  }
}

export default IndexPage
