import React from "react"

import Logo from "../images/logo.svg"
import TVLogo from "../images/BlackAlpacaTV_logo.svg"

export default class Playlist extends React.Component {
  // constants
  videoBG
  preloader
  // calculated field
  playlistDuration = this.calculatePlaylistDuration

  constructor(props) {
    super(props)

    this.videoBG = React.createRef()
    this.preloader = React.createRef()
    this.videoRefs = React.createRef()

    this.videoRefs.current = []

    this.state = {
      playlist: [],
      videos: [],
      currentVideo: null,
      loadedVideos: 0,
    }

    this.populatePlaylist()
  }

  render() {
    return (
      <>
        <div id="preloader" className="visible" ref={this.preloader}>
          <div className="preloader-bg">
            <Logo className="logo-big" />
          </div>
        </div>
        <aside id="video-bg" ref={this.videoBG}>
          <TVLogo className="static-logo" />
          <videos>
            {this.state.playlist.map(({ src }) => (
              <video
                ref={el => this.videoRefs.current.push(el)}
                key={src}
                src={src}
                playsInline={true}
                preload="none"
                controls={false}
                muted={true}
                style={{ display: "none" }}
                onEnded={this.boundOnVideoEnded}
                onPlaying={e => this.boundFadeoutPreloader(e)}
                onCanPlayThrough={this.boundPrepareNextVideo}
              />
            ))}
          </videos>
        </aside>
      </>
    )
  }

  /**
   * Called from the constructor.
   * This function fetches the JSON playlist generated by an external PHP script.
   * The JSON needs to consist of [{src: URL, duration: Number}]
   * It is saved in this.state.playlist, which is used to create video elements
   * in the render loop.
   */
  async populatePlaylist() {
    try {
      const res = await fetch("./playlist-generator.php")
      const data = await res.json()

      this.setState({ ...this.state, playlist: data })
      this.setActiveVideo()
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * @returns {Int} The summed duration of all videos in this.state.playlist.
   */
  calculatePlaylistDuration() {
    return this.state.playlist.reduce(
      (sum, currentValue) => (sum += currentValue.duration),
      0
    )
  }

  /**
   * In order to synchronise playback, this function gets the user's current Date(), and extracts
   * from it the offset relative to each day's 00:00.
   * @returns {Int} nowSeconds
   */
  calculatePlaylistTimeOffset() {
    const now = new Date()
    let nowSeconds =
      now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds()

    return nowSeconds % this.playlistDuration()
  }

  /**
   * Sets the correct video via ref and seeks it according to the playlist time offset.
   */
  setActiveVideo() {
    const now = this.calculatePlaylistTimeOffset()
    const [videoRef, seekTime] = this.skipOverPastVideos(now)
    videoRef.preload = "auto"

    const activateVideo = e => {
      videoRef.currentTime = seekTime
      videoRef.style.display = "initial"
      videoRef.classList.toggle("video-active")
      videoRef.play()
      this.setState({ ...this.state, currentVideo: videoRef })
      e.target.removeEventListener("progress", activateVideo)
    }

    videoRef.addEventListener("progress", activateVideo)
  }

  /**
   * Loops over this.state.playlist until it reaches the video that should be played.
   * The remaining time is used as information where to seek that video, so the whole
   * offset is consumed.
   *
   * @param {Int} time
   * @returns {[HTMLVideoElement, Int]} [currentVideo, seekTime]
   */
  skipOverPastVideos(time) {
    let currentVideoRef, seekTime
    let pastVideosDuration = 0,
      currentVideoIndex = 0

    while (
      pastVideosDuration + this.state.playlist[currentVideoIndex].duration <=
      time
    ) {
      pastVideosDuration += this.state.playlist[currentVideoIndex].duration
      currentVideoIndex++
    }

    currentVideoRef = this.videoRefs.current[currentVideoIndex]
    seekTime = time - pastVideosDuration
    return [currentVideoRef, seekTime]
  }

  // helpers
  fadeOutPreloader(e) {
    this.preloader.current.classList.remove("visible")
  }

  prepareNextVideo() {
    if (!this.state.currentVideo) return

    let next
    if (!this.state.currentVideo.nextSibling) {
      next = this.state.currentVideo.parentElement.firstChild
    } else {
      next = this.state.currentVideo.nextSibling
    }

    next.preload = "metadata"
  }

  onVideoEnded() {
    const currentVideo = this.state.currentVideo
    currentVideo.style.display = "none"
    currentVideo.classList.toggle("video-active")

    const playNextVideo = () => {
      const currentVideo = this.state.currentVideo
      currentVideo.style.display = "initial"
      currentVideo.classList.toggle("video-active")
      currentVideo.play()
    }

    if (!this.state.currentVideo.nextSibling) {
      this.setState(
        {
          ...this.state,
          currentVideo: this.state.currentVideo.parentElement.firstChild,
        },
        () => playNextVideo()
      )
    } else {
      this.setState(
        {
          ...this.state,
          currentVideo: this.state.currentVideo.nextSibling,
        },
        () => playNextVideo()
      )
    }
  }

  boundOnVideoEnded = () => this.onVideoEnded()
  boundFadeoutPreloader = e => this.fadeOutPreloader(e)
  boundPrepareNextVideo = () => this.prepareNextVideo()
}
